<template>
	<div class="container">
		<div class="ewm page-sub-box">
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<table class="table_form line-bin min-w-max max-w-screen-xl">
						<colgroup>
							<col style="width:90px;" />
							<col style="width:200px;" />
							<col style="width:120px;" />
							<col style="width:200px;" />
							<col style="width:100px;" />
							<col style="width:100px;" />
							<col style="width:100px;" />
							<col style="width:auto;" />
						</colgroup>

						<tbody>
							<tr>
								<th scope="row">
									<label for="label01">평가계획:</label>
								</th>
								<td>
									{{ evalScheduleInfo.scheNm }}
								</td>
								<th scope="row">
									<label for="label01">진행상태:</label>
								</th>
								<td>
									{{ this.$_enums.ewm.evalProcess.values.find(e => evalScheduleInfo.evalProcessCd == e.value).label }}
								</td>
								<th scope="row">
									<label for="label01">평가회차:</label>
								</th>
								<td>{{ evalScheduleInfo.evalRound }}회차</td>
								<th scope="row">
									<label for="label01">평가표:</label>
								</th>
								<td>
									{{ evalScheduleInfo.sheetNm }}
								</td>
							</tr>
							<tr>
								<th scope="row">
									<label for="label01">평가기간:</label>
								</th>
								<td>
									{{ evalScheduleInfo.evalStartDt }}
									~
									{{ evalScheduleInfo.evalEndDt }}
								</td>
								<th scope="row">
									<label for="label01">이의제기 기간:</label>
								</th>
								<td>
									{{ evalScheduleInfo.evalFeedbackStartDt }}
									~
									{{ evalScheduleInfo.evalFeedbackEndDt }}
								</td>
								<th scope="row">
									<label for="label01">평가대상자:</label>
								</th>
								<td colspan="3">{{ evalScheduleInfo.targetNm }} [ {{ evalScheduleInfo.targetId }} ]</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="flex gap-x-6">
					<div class="w-1/2 flex flex-col gap-y-6 divide-y">
						<div v-if="evalScheduleInfo.evalDivisionNm !== '일반평가'">
							<h3 class="text-xl font-medium my-4">콜정보</h3>
							<div>
								<div class="mt-4">
									<DxDataGrid
										key-expr="id"
										class="grid-box eval-grid"
										ref="evalRoundRecordGrid"
										:data-source="roundRecordGridData.dataSource"
										:allow-column-resizing="true"
										:column-resizing-mode="'nextColumn'"
										:show-borders="false"
										:show-column-headers="true"
										:show-column-lines="true"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:hover-state-enabled="true"
										:word-wrap-enabled="true"
										:no-data-text="this.$_msgContents('CMN_NO_DATA')"
										:selected-row-keys="roundRecordGridData.selectedRowKeys"
										width="100%"
										height="230"
									>
										<DxLoadPanel :enabled="true" />

										<DxScrolling mode="virtual" />
										<DxSelection
											mode="multiple"
											:allow-select-all="true"
											show-check-boxes-mode="always"
											select-all-mode="allPages"
										/>

										<DxColumn caption="구분" data-field="recTypeCd" alignment="center" :allow-sorting="true">
											<DxLookup
												:data-source="this.$_enums.ewm.evalCallYype.values"
												value-expr="value"
												display-expr="label"
											/>
										</DxColumn>
										<DxColumn caption="통화일" data-field="recDate" alignment="center" :allow-sorting="true" />
										<DxColumn
											caption="통화시간"
											data-field="recStartTime"
											alignment="center"
											:allow-sorting="true"
											:calculate-display-value="calDisplayValRecElapsedTime"
										/>
										<DxColumn caption="콜ID" data-field="callId" alignment="center" :allow-sorting="true" />
										<DxColumn
											caption="청취"
											data-field="id"
											cell-template="playTemplate"
											:allow-sorting="false"
											alignment="center"
											:visible="true"
											:width="55"
										/>
										<template #playTemplate="{ data }">
											<DxButton
												text=""
												:element-attr="{ class: 'btn_XS white outlined mr-0' }"
												height="30"
												hint="청취"
												template="<span class='mdi mdi-volume-high'></span>"
												@click="onRoundRecordPlay($event, data.data)"
											/>
										</template>
									</DxDataGrid>
								</div>
							</div>
						</div>
						<div>
							<h3 class="text-xl font-medium my-4">평가표 진행 현황</h3>
							<div class="mt-4">
								<DxDataGrid
									key-expr="questionId"
									class="grid-box eval-grid"
									ref="evalAnswerStatusGrid"
									:data-source="answerStatusGridData.dataSource"
									:allow-column-resizing="true"
									:column-resizing-mode="'nextColumn'"
									:show-borders="false"
									:show-column-headers="true"
									:show-column-lines="true"
									:show-row-lines="true"
									:row-alternation-enabled="false"
									:hover-state-enabled="true"
									:word-wrap-enabled="true"
									:no-data-text="this.$_msgContents('CMN_NO_DATA')"
									:selected-row-keys="answerStatusGridData.selectedRowKeys"
									width="100%"
									height="230"
									@selection-changed="onAnswerStatusGridSelectionChanged"
								>
									<DxLoadPanel :enabled="true" />

									<DxScrolling mode="virtual" />
									<DxSelection
										mode="single"
										:allow-select-all="false"
										show-check-boxes-mode="always"
										select-all-mode="allPages"
									/>

									<DxColumn
										caption="평가구성"
										data-field="questionParentGroup"
										alignment="center"
										:allow-sorting="true"
									/>
									<DxColumn caption="" data-field="questionChildGroup" alignment="center" :allow-sorting="true" />
									<DxColumn
										caption="문항"
										data-field="questionNm"
										:allow-sorting="true"
										cell-template="questionNmTemplate"
									/>
									<template #questionNmTemplate="{ data }">
										<div class="flex">
											<span
												class="inline-block w-3.5 h-3.5 mt-1 mr-2 rounded-sm"
												:class="getQuestionNmStatusBg(data.data)"
											></span>
											<span>{{ data.data.questionNm }}</span>
										</div>
									</template>
									<DxColumn
										caption="배점"
										data-field="questionScore"
										alignment="center"
										:allow-sorting="true"
										width="60"
									/>
									<DxColumn caption="평가" data-field="answerScore" alignment="center" :allow-sorting="true" width="60" />
								</DxDataGrid>
							</div>
						</div>
					</div>
					<div class="w-2/3">
						<h3 class="text-xl font-medium my-4">평가표</h3>
						<div>
							<div class="overflow-auto border p-2" style="height: 600px;" id="questionAnswerContainer">
								<table
									v-for="questionAnswer in evalSheetData.questionAnswer.questionList"
									:key="questionAnswer.questionId"
									:id="`questionAnswer_${questionAnswer.questionId}`"
									class="border-collapse table-fixed mb-6"
								>
									<thead>
										<tr>
											<th class="border text-left py-4 bg-gray-100">
												<DxButton
													:template="
														evalSheetData.questionAnswer.isVisible.question[`${questionAnswer.questionId}`]
															? '<span class=\'mdi mdi-chevron-down\'></span>'
															: '<span class=\'mdi mdi-chevron-up\'></span>'
													"
													type="button"
													class="bg-gray-100 w-14 inline-block text-center"
													:active-state-enabled="false"
													:focus-state-enabled="false"
													:hover-state-enabled="false"
													height="20"
													@click="
														evalSheetData.questionAnswer.isVisible.question[
															`${questionAnswer.questionId}`
														] = !evalSheetData.questionAnswer.isVisible.question[`${questionAnswer.questionId}`]
													"
												/>
												<span>{{ questionAnswer.questionNm }}</span>
											</th>
											<th class="border text-center py-4 bg-gray-100 w-20">배점</th>
											<th class="border text-center py-4 bg-gray-100 w-20">평가</th>
											<th class="border text-center py-4 bg-gray-100 w-20">이의제기</th>
										</tr>
									</thead>
									<tbody :class="{ hidden: !evalSheetData.questionAnswer.isVisible.question[questionAnswer.questionId] }">
										<tr v-for="questionOption in questionAnswer.questionOptions" :key="questionOption.optionId">
											<td class="border px-8 py-4">{{ questionOption.optionNm }}</td>
											<td class="border text-center py-4">{{ questionOption.optionScore }}</td>
											<td class="border text-center py-4">
												<DxCheckBox
													:value="questionAnswer.answerId === questionOption.optionId"
													:disabled="true"
													@value-changed="
														e => {
															if (e.value) {
																questionAnswer.answerId = questionOption.optionId;
																questionAnswer.answerScore = questionOption.optionScore;
															} else {
																if (questionAnswer.answerId == questionOption.optionId) {
																	questionAnswer.answerId = null;
																	questionAnswer.answerScore = null;
																}
															}
														}
													"
												/>
											</td>
											<td class="border text-center py-4">
												<DxCheckBox
													:value="questionAnswer.feedbackAnswerId === questionOption.optionId"
													:disabled="evalScheduleInfo.evalProcessCd !== ewmCodes.evalProcess.FEEDBACK.value"
													@value-changed="
														e => {
															if (e.value) {
																questionAnswer.feedbackAnswerId = questionOption.optionId;
																questionAnswer.feedbackScore = questionOption.optionScore;
															} else {
																if (questionAnswer.feedbackAnswerId == questionOption.optionId) {
																	questionAnswer.feedbackAnswerId = null;
																	questionAnswer.feedbackScore = null;
																}
															}
														}
													"
												/>
											</td>
										</tr>
									</tbody>
									<thead>
										<tr>
											<th colspan="4" class="border text-left py-4 bg-gray-100">
												<DxButton
													:template="
														evalSheetData.questionAnswer.isVisible.comment[`${questionAnswer.questionId}`]
															? '<span class=\'mdi mdi-chevron-down\'></span>'
															: '<span class=\'mdi mdi-chevron-up\'></span>'
													"
													type="button"
													class="bg-gray-100 w-14 inline-block text-center"
													:active-state-enabled="false"
													:focus-state-enabled="false"
													:hover-state-enabled="false"
													height="20"
													@click="
														evalSheetData.questionAnswer.isVisible.comment[
															questionAnswer.questionId
														] = !evalSheetData.questionAnswer.isVisible.comment[`${questionAnswer.questionId}`]
													"
												/>
												<span>평가의견</span>
											</th>
										</tr>
									</thead>
									<tbody :class="{ hidden: !evalSheetData.questionAnswer.isVisible.comment[questionAnswer.questionId] }">
										<tr>
											<td colspan="4" class="border p-2">
												<div style="min-height:30px;">
													<DxTextArea
														:height="90"
														:element-attr="{ class: 'border p-2' }"
														v-model="questionAnswer.evalComment"
														:read-only="true"
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="flex justify-between p-3 gap-4 my-4 bg-gray-100" style="height:140px">
								<div class="w-full">
									<div class="text-center text-xl">평가의견</div>
									<div class="bg-white border rounded">
										<DxTextArea
											:height="90"
											:element-attr="{ class: 'p-2' }"
											v-model="evalSheetData.evalTargetComment.evalComment"
											:read-only="true"
										/>
									</div>
								</div>
								<div class="w-full">
									<div class="text-center text-xl">이의제기의견</div>
									<div class="bg-white border rounded">
										<DxTextArea
											:height="90"
											:element-attr="{ class: 'p-2' }"
											v-model="evalSheetData.evalTargetComment.feedback"
											:read-only="evalScheduleInfo.evalProcessCd !== this.$_enums.ewm.evalProcess.FEEDBACK.value"
										/>
									</div>
								</div>
								<div class="w-full">
									<div class="text-center text-xl">최종평가의견</div>
									<div class="bg-white border rounded">
										<DxTextArea
											:height="90"
											:element-attr="{ class: 'p-2' }"
											v-model="evalSheetData.evalTargetComment.finalComment"
											:read-only="true"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<h2 class="hidden">일반 버튼</h2>
				<div class="bottom-btn-wrap">
					<DxButton
						text="저 장"
						class="default filled txt_S medium"
						:width="100"
						:height="40"
						:visible="evalScheduleInfo.evalProcessCd == this.$_enums.ewm.evalProcess.FEEDBACK.value"
						@click="onSaveFormData"
					/>
					<DxButton text="취 소" class="btn_XS white filled txt_S medium" :width="100" :height="40" @click="onCancelFormData" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDataGrid, DxColumn, DxLoadPanel, DxLookup, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
import { DxTextArea } from 'devextreme-vue/text-area';
import {calculateElapsedTime, cloneObj, formatDate, isSuccess} from "@/plugins/common-lib";

let vm = null;

export default {
	components: {
		DxButton,
		DxCheckBox,

		DxTextArea,

		DxDataGrid,
		DxColumn,

		DxLoadPanel,
		DxLookup,
		DxScrolling,
		DxSelection,
	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
			dataGridLoopup: {
				evalDivisionCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				evalProcessCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				recDrawTypeCd: [],
			},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			roundRecordGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			answerStatusGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			evalSheetData: {
				questionAnswer: {
					questionList: [],
					isVisible: {
						question: {},
						comment: {},
					},
					isCheckSetting: false,
				},
				evalTargetComment: {},
			},
			evalScheduleInfo: {
				id: null,
				evalDivisionCd: null,
				evalDivisionNm: null,
				scheNm: null,
				evalSheetId: null,
				sheetNm: null,
				scheYmd: null,
				evalStartDt: null,
				evalEndDt: null,
				evalFeedbackStartDt: null,
				evalFeedbackEndDt: null,
				evalProcessCd: null,
				evalProcessNm: null,
				viewFl: 'Y',
				delFl: 'N',
				roundId: '',
				targetAgtId: null,
				targetId: null,
				canModify: true,
			},
		};
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		/** @description : 콜정보 조회 */
		async selectRoundRecordDataList() {
			if (!vm.evalScheduleInfo.id) {
				return;
			}
			const payload = {
				actionname: 'EWM_EVALUATION_STATUS_ROUND_RECORD',
				data: {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.evalScheduleInfo.evalRound ? vm.evalScheduleInfo.evalRound : '',
					agtId: vm.evalScheduleInfo.targetId,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.roundRecordGridData.dataSource = res.data.data;
			}
		},
		/** @description : 평가표 진행 현황 조회 */
		async selectAnswerStatusData() {
			if (!vm.evalScheduleInfo.id) {
				return;
			}

			const payload = {
				actionname: 'EWM_EVALUATION_STATUS_ANSWER_STATUS_LIST',
				data: {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.evalScheduleInfo.evalRound ? vm.evalScheduleInfo.evalRound : '',
					targetAgtId: vm.evalScheduleInfo.targetId,
					sheetId: vm.evalScheduleInfo.evalSheetId,
					targetId: vm.evalScheduleInfo.targetSeqId,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.answerStatusGridData.dataSource = res.data.data[0].questionAnswers;

				this.evalSheetData.questionAnswer.questionList = cloneObj(this.answerStatusGridData.dataSource);
				this.evalSheetData.evalTargetComment = cloneObj(res.data.data[0].evalTargetComment);

				const questionAnswerVisible = {};
				res.data.data[0].questionAnswers.forEach(z => (questionAnswerVisible[z.questionId] = true));

				this.evalSheetData.questionAnswer.isVisible.question = questionAnswerVisible;
				this.evalSheetData.questionAnswer.isVisible.comment = cloneObj(questionAnswerVisible);
			}
		},
		/** @description : 통화시간 계산 */
		calDisplayValRecElapsedTime(rowData) {
			const startTime = `${rowData.recDate}T${formatDate(rowData.recStartTime, 'HHmmSS', 'HH:mm:SS')}`;
			const endTime = `${rowData.recDate}T${formatDate(rowData.recEndTime, 'HHmmSS', 'HH:mm:SS')}`;

			return calculateElapsedTime(startTime, endTime, ':');
		},
		/** @description : 콜정보 청취 */
		onRoundRecordPlay(data) {
			this.$_Msg('준비중');
		},
		/** @description : 평가표진행현황 문항별 상태 class 설정 */
		getQuestionNmStatusBg(data) {
			if (data.feedbackAnswerId) {
				return ' bg-purple-500';
			} else if (data.answerId) {
				return ' bg-green-500';
			} else {
				return ' bg-gray-300';
			}
		},
		/** @description : 평가표진행현황 선택 변경 */
		onAnswerStatusGridSelectionChanged(e) {
			if (e.selectedRowsData.length) {
				const questionAnswerContainer = document.getElementById('questionAnswerContainer');
				const questionItem = document.getElementById(`questionAnswer_${e.selectedRowsData[0].questionId}`);

				questionAnswerContainer.scrollTop = questionItem.offsetTop;
			}
		},
		/** @description : 저장 버튼 클릭 */
		async onSaveFormData() {
			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' })))) {
				return;
			}

			const data = {
				targetAnswer: [],
				id: this.evalScheduleInfo.targetSeqId,
				evalComment: this.evalSheetData.evalTargetComment.evalComment,
				feedback: this.evalSheetData.evalTargetComment.feedback,
				finalComment: this.evalSheetData.evalTargetComment.finalComment,
			};

			data.targetAnswer = this.evalSheetData.questionAnswer.questionList.map(z => {
				return {
					id: z.evalTargetAnswerId,
					targetId: vm.evalScheduleInfo.targetSeqId,
					questionId: z.questionId,
					answerId: z.answerId,
					score: z.answerScore,
					evalComment: z.evalComment,
					feedbackAnswerId: z.feedbackAnswerId,
					feedbackScore: z.feedbackScore,
				};
			});

			const payload = {
				actionname: 'EWM_EVALUATION_STATUS_EVAL_TARGET_COMMENT',
				data: data,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					await this.selectAnswerStatusData();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 닫기 버튼 클릭 메서드 */
		onCancelFormData() {
			this.$router.push('/ewm/personal/evaluation');
		},

		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
		},
		/** @description: 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			vm = this;

			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}

			this.evalScheduleInfo = this.reqParams.data;

			await this.initCodeMap().then(() => {
				this.dataGridLoopup.evalDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
			});

			this.evalScheduleInfo.evalDivisionNm = this.dataGridLoopup.evalDivisionCd.dataSource.find(
				d => d.codeId == this.evalScheduleInfo.evalDivisionCd,
			).codeNm;

			console.log('this.evalScheduleInfo', this.evalScheduleInfo);

			//if (this.evalScheduleInfo.evalDivisionCd !== 1134) {
			if (this.evalScheduleInfo.evalDivisionNm !== '일반평가') {
				this.selectRoundRecordDataList();
			}

			this.selectAnswerStatusData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>
